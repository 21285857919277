import React from 'react';

const styles = {
    "customLoading": {
      "WebkitAnimation": "sk-scaleout 1.0s infinite ease-in-out",
      "animation": "sk-scaleout 1.0s infinite ease-in-out",
      "backgroundColor": "rgb(4, 49, 85)",
      "borderRadius": "100%",
      "height": "6em",
      "width": "6em"
    },
    "customLoaderContainer": {
      "flexDirection": "column",
      "alignItems": "center",
      "backgroundColor": "#e6e4e0",
      "display": "flex",
      "height": "100vh",
      "justifyContent": "center",
      "width": "100vw"
    },
    "waitingText": {
      "fontWeight": "bold",
      "margin": "10px",
      "color": "rgb(4, 49, 85)"
    }
  };

function Loader() {
    return (<>
        <div id="spinner" style={styles.customLoaderContainer}>
        <div style={styles.waitingText}>Loading...</div>
        <div style={styles.customLoading}></div>
        </div>
    </>)
}

export default Loader;
