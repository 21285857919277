import React, { useRef, useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import axios from 'axios';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import { Modal, Container, Row, Col, Form, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as turf from "@turf/turf";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faArrowsAltH, faExpandArrowsAlt, faTimes, faCar, faClock, faSearch, faFile, faQuestionCircle, faPlusSquare, faMinusSquare, faPhone } from '@fortawesome/free-solid-svg-icons'
// import { Marker } from 'mapbox-gl';
import { API_URL } from '../my_config';
import Loader from './Loader';
import Slider, { SliderTooltip  } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tour from 'reactour';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYXRvbWljZ3JvdXAiLCJhIjoiY2tsa21xN3ZwMDRmeDJ2bjR3NjloaDQxZCJ9.0pmL16fQeN4HGAUUheY91g';


const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

const wrapperStyle = { width: '100%', padding:5 };

var lat = -70.9;
var lng = 42.35;
// var zoom = 14;
// var propertyData = [];
var map1;
let initialState = {
  id:0,
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  postCode: "",
  hear_about_us_id: 6,
  note: ''
}

var optionsList = ["How you did you hear about us", "facebook", "twitter", "linkedin", "News letter", "from freind", "other"];
var nearPlaces = [];
var infoWindowList = [];
var amenitiesList = [];

var CurrentlotsCounter = 0;
var SoldlotsCounter = 0;
var lotFrontageArrRange = [8, 20];
var lotSizeArrRange = [290, 460];
var filteredData;
const Cameroon = () => {
  const mapContainerRef = useRef();
  // const [lng, setLng] = useState(-70.9);
  // const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(14);
  const [zoomLevel, setZoomLevel] = useState([15, 16, 17]);
  let [propertyData, setPropertyData] = useState([]);
  let [lotSearch, setLotSearch] = useState(false);
  let [lotSearchMsg, setLotSearchMsg] = useState("");
  let [filteredLots, setFilteredLots] = useState([]);
  const [renderView, SetRenderView] = useState(0);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState({});
  let [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [placeTypeObj, setPlaceTypeObj] = useState({});
  const [responseReceived, setResponseReceived] = useState({
    message: '',
    class: ''
  });
  const [releasesList, setReleasesList] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const [airPortModalDisplay, setAirPortModalDisplay] = useState(false);
  const [selectedAirPort, setSelectedAirPort] = useState({});
  const [airPortList, setAirPortList] = useState([
    {id: 0, lat:"-27.568831058" , lng: "153.004833314", name: "Doug Bruhn Way, Coombabah QLD 4216, Australia", website_url:"https://www.google.com/"},
    {id: 1, lat:"-28.165760" , lng: "153.515679", name: "Terminal Dr, Bilinga QLD 4225, Australia", website_url:"https://www.google.com/"}
  ]);

  const [amenitiesModalDisplay, setAmenitiesModalDisplay] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState({});

  const steps = [
    {
      selector: '.zoom-control-wrapper',
      content: ({ goTo, inDOM }) => (
        <>
          <div>
            <span className='mb-1'>
              <strong>Zoom Out</strong> to see where Calypso Bay is located.
              <br />
            </span>
            <span>
              <strong>Zoom In</strong> to learn more about Calypso Bay & see lots
              for sale.
            </span>
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => setIsTourOpen(false)}>
                Cancel
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => goTo(1)}>
                Continue
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      selector: '.places-types',
      content: ({ goTo, inDOM }) => (
        <>
          <div className='pt-2'>
            Click on these categories to see important places which are near
            Calypso Bay
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => setIsTourOpen(false)}>
                Cancel
              </button>
              <button className='tour-btn' onClick={() => goTo(0)}>
                Prev
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => goTo(2)}>
                Next
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      selector: '.search-near-places',
      content: ({ goTo, inDOM }) => (
        <>
          <div className='pt-2'>
            Search here to find what is located near Calypso Bay
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => setIsTourOpen(false)}>
                Cancel
              </button>
              <button className='tour-btn' onClick={() => goTo(1)}>
                Prev
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => goTo(3)}>
                Next
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      selector: '.search-directions',
      content: ({ goTo, inDOM }) => (
        <>
          <div className='pt-2'>
            Search here to find how far it is to your work etc.
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => setIsTourOpen(false)}>
                Cancel
              </button>
              <button className='tour-btn' onClick={() => goTo(2)}>
                Prev
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => goTo(4)}>
                Next
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      selector: '.lots-search',
      content: ({ goTo, inDOM }) => (
        <>
          <div className='pt-2'>Search for land which fit your requirements.</div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => setIsTourOpen(false)}>
                Cancel
              </button>
              <button className='tour-btn' onClick={() => goTo(3)}>
                Prev
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => goTo(5)}>
                Next
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      selector: '.zoom-to',
      content: ({ goTo, inDOM }) => (
        <>
          <div className='pt-2'>
            Zoom directly to a stage where lots are available for sale.
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => setIsTourOpen(false)}>
                Cancel
              </button>
              <button className='tour-btn' onClick={() => goTo(4)}>
                Prev
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => goTo(6)}>
                Next
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      selector: '.stage-brochures',
      content: ({ goTo, inDOM }) => (
        <>
          <div className='pt-2'>
            Print PDF files showing what land is available.
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div>
              <button className='tour-btn' onClick={() => goTo(5)}>
                Prev
              </button>
            </div>
            <div>
              <button className='tour-btn' onClick={() => {setIsTourOpen(false); goTo(0);}}>
                Close
              </button>
            </div>
          </div>
        </>
      ),
    },
  ];
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()){
      sendEnquiry();
    };
  }

const changeSelect = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]:value
    });
}

const changeZoomTo = (e) => {
  const { name, value, } = e.target;
  const selectedRelease = releasesList.find(obj => obj.id == value);
  var lngFly = selectedRelease.lng;
  var latFly = selectedRelease.lat;
  var zoom_level = selectedRelease.zoom_level;
  map1.flyTo({
    center: [lngFly, latFly],
    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    zoom: zoom_level
  });

}

const validate = (fieldValues = values) => {
  let temp = { ...errors }
  if ('firstName' in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "This field is required."
  if ('lastName' in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "This field is required."
  if ('mobile' in fieldValues)
      temp.mobile = fieldValues.mobile ? "" : "This field is required."
  if ('email' in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required."
  if ('note' in fieldValues)
      temp.note = fieldValues.note ? "" : "This field is required."
  if ('hear_about_us_id' in fieldValues)
     temp.hear_about_us_id = fieldValues.hear_about_us_id ? "" : "This field is required."
  // if ('country_id' in fieldValues)
  //     temp.country_id =  fieldValues.country_id !== 0 && fieldValues.country_id !== null ? "" : "This field is required."
  
  setErrors({
      ...temp
  })

  if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
}

const sendEnquiryToRex = () => {

  // Listing::search
  let postData1 = { ...values, _id :selectedPlot._id }
  var msg_body = `${values.note}

  
   Name: ${values.firstName}  ${values.lastName}
   Email: ${values.email} 
   Phone: ${values.mobile}  
   Postcode: ${values.postCode} 
   hear about us: ${optionsList[values.hear_about_us_id]}`;
const postData = {
    "method": "Leads::create",
    "args": {
        "data": {
          "note": msg_body,
          // "contact": {
          //   "id": 5
          // },
          // "project": {
          //   "id": 15
          // },
          // "project_stage": {
          //   "id": 3
          // },
          "listing": {
            "id": selectedPlot._id
          },
          "property": {
            "id": selectedPlot.property_id
          },
          // "assignee": {
          //   "id": 1
          // },
          "lead_type": {
            "id": "appraisal_request"
          },
          // "lead_source": {
          //   "id": 1
          // }
      }
    },
    "token": `${localStorage.getItem('token')}`
  };

axios.post('https://api.rexsoftware.com/rex.php', postData).then((response) => {
  let propertyDataResp = response.data;
    if(propertyDataResp.error === null){
      setResponseReceived({
        message: 'enquiry submitted successfully.',
        class: 'success'
      });
      setValues({});
      setTimeout(() => {
        setResponseReceived({
          message: '',
          class: ''
        });      
        setModalDisplay(false);
        setSelectedPlot({});
      }, 3000);
    }
  });
}

const sendEnquiry = async () => {
  sendEnquiryToRex();
  return false;
  // return for only save enquiry on rex 
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };
  let postData = { ...values, _id :selectedPlot._id }
  try {
      const res = await axios.post(API_URL + `/api/enquiry`, postData, config);
      if(res.data.error === false){
          setResponseReceived({
            message: res.data.message,
            class: 'success'
          });
          setValues({});
          setTimeout(() => {
            setResponseReceived({
              message: '',
              class: ''
            });      
            setModalDisplay(false);
            setSelectedPlot({});
          }, 3000);
      }
  } catch (err) {
    // validation error
    // if(err.response.status === 422){
    //   let temp = { ...errors };
    //   Object.entries(err.response.data.data).forEach(([key, value]) => {
    //     temp[key] = value;
    //   });
    //   setErrors({
    //     ...temp
    //   });
    // }
    // internal server error
    if(err.response.status === 500){
      setResponseReceived({
        message: err.data.message,
        class: 'danger'
      });
    }
  }
  
}

  const loginApi = () => {
    const postData = {
      "method": "Authentication::login",
      "args": {
        "email":  "CMoloney@hvg.com.au",//"DMcKenna@rochegroup.com.au",
        "password":  "1TrMIWOe3h1JEA1n",//"Ducati848",
        "application": "rex"
      }
   }
  axios.post('https://api.rexsoftware.com/rex.php', postData).then((response) => {    
    if( response.data.result !== null ){
      localStorage.setItem("token", response.data.result);
      getSoldPlotsData();
      getCurrentPlotsData();
    }else{
      console.log(response.data.error.message);
    }
  });
}

const getSoldPlotsData = () => {
  // Listing::search 
  const postData = {
      "method": "PublishedListings::search",
      "args": {
        "criteria": [
          {
            "name": "listing.system_listing_state",
            "value": "sold"
          }
        ],
        "offset": SoldlotsCounter,
        "limit": 100,
        "order_by": {
          "system_ctime": "asc"
        }
      },
      "token": `${localStorage.getItem('token')}`
    };

  axios.post('https://api.rexsoftware.com/rex.php', postData).then((response) => {
    if( response.data.result !== null ){
      let propertyDataResp = response.data.result.rows;
      propertyData = propertyData.concat(propertyDataResp);
      setPropertyData(propertyData);
      if(response.data.result.total > SoldlotsCounter){
          SoldlotsCounter = SoldlotsCounter + 100;
          getSoldPlotsData();
          return;
      }
    }else{
      console.log(response.data.error.message);
    }
  });
}

  const getCurrentPlotsData = () => {
      // Listing::search 
    const postData = {
        "method": "PublishedListings::search",
        "args": {
          "criteria": [
            {
              "name": "listing.system_listing_state",
              "value": "current"
            }
          ],
          "offset": CurrentlotsCounter,
          "limit": 100,
          "order_by": {
            "system_ctime": "asc"
          }
        },
        "token": `${localStorage.getItem('token')}`
      };

    axios.post('https://api.rexsoftware.com/rex.php', postData).then((response) => {
        if( response.data.result !== null ){
          let propertyDataResp = response.data.result.rows;
          propertyData = propertyData.concat(propertyDataResp);
          setPropertyData(propertyData);
          // SetRenderView( renderView + 1 );
          if(response.data.result.total > CurrentlotsCounter){
              CurrentlotsCounter = CurrentlotsCounter + 100;
              getCurrentPlotsData();
              return;
          }
        }
    });
  }

  const getPlacesList = async() => {
    try {
      const response = await axios.get( API_URL + "/api/places/types");
      if(response.data.error === false){
        nearPlaces = response.data.data.map((obj)=>{
            return { ...obj, active: false }
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getReleasesList = async() => {
    try {
      const response = await axios.get( API_URL + "/api/places/releases/2");
      if(response.data.error === false){
        setReleasesList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getInfoWindow = async() => {
    try {
      const response = await axios.get( API_URL + "/api/places/get-info-window-list");
      if(response.data.error === false){
        infoWindowList = response.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getAmenities = async() => {
    try {
      const response = await axios.get( API_URL + "/api/places/get-amenities-list");
      if(response.data.error === false){
        amenitiesList = response.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createCustomMarker = () => {
    // Query all rendered features from a single layer
    var features = map1.queryRenderedFeatures({ layers: ['unclustered-point'] });
    console.log(features);
    for (var i = 0; i < features.length; i++) {
      var coords = features[i].geometry.coordinates;
      var props = features[i].properties;
      // if (!props.cluster) continue;
      
      var el = document.createElement('div');
      el.className = 'marker custom-marker';
      el.style.backgroundImage = `url(../images/${props.type_title}-active.png)`;
      var Marker = new mapboxgl.Marker(el)
        .setLngLat(coords)
        .addTo(map1);
      Marker.getElement().addEventListener('click', () => {
        setPlaceTypeObj(props);
      });
    }

  }

  const customMarkerClustering = (result) => {
    var featuresList = result.map((obj) => {
      return {
        type: 'Feature',
        properties: obj,
        geometry: {
          type: 'Point',
          coordinates: [obj.lng, obj.lat],
        },
      };
    });
    var jsonObject = {
      type: 'FeatureCollection',
      features: featuresList,
    };

    if (map1.getLayer('clusters')) {
      map1.getSource('marker-cluster').setData(jsonObject);
    } else {
      map1.addSource('marker-cluster', {
        type: 'geojson',
        data: jsonObject,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      map1.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'marker-cluster',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map1.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'marker-cluster',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      map1.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'marker-cluster',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    }

    // inspect a cluster on click
    map1.on('click', 'clusters', function (e) {
      var features = map1.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      var clusterId = features[0].properties.cluster_id;
      map1
        .getSource('marker-cluster')
        .getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;

          map1.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    map1.on('click', 'unclustered-point', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var mag = e.features[0].properties.mag;
      var properties = e.features[0].properties;
      var tsunami;

      if (e.features[0].properties.tsunami === 1) {
        tsunami = 'yes';
      } else {
        tsunami = 'no';
      }

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
    });

    map1.on('render', function () {
      if (map1.getLayer('clusters') && map1.getZoom() < zoomLevel[0]) {
        createCustomMarker();
      } else {
        removeLayerSourceAndCustomMarkers();
      }
    });
  }

  // get data for marker from api
  const getPlacesByTypeId = async (id) => {
    axios.get( API_URL + `/api/places/get-places-by-type-id/${id}`).then((response) => {
        var result = response.data.data;
        setPlaceTypeObj({});

        // customMarkerClustering(result);
        // to use above function 'customMarkerclustring' comment below function 
        // custom markers code 
        let markers = document.getElementsByClassName("mapboxgl-marker");
        for (let i = 0; i < markers.length; i++) {
            markers[i].style.visibility = "hidden";
        }
        result.map((obj)=> {
          var el = document.createElement('div');
          el.id = "marker-" + obj.id;
          el.className = 'marker custom-marker';
          el.style.backgroundImage = `url(../images/${obj.type_title}-active.png)`;
          var Marker = new mapboxgl.Marker(el, { offset: [0, -23] })
            .setLngLat([Number(obj.lng), Number(obj.lat)])
            .addTo(map1);
          Marker.getElement().addEventListener('click', () => {
            setPlaceTypeObj(obj);
          });
        });
        //end custom markers code
    });
  }

  function removeLayerSourceAndCustomMarkers() {
      document.querySelectorAll('.custom-marker').forEach(function(a){
        a.remove()
      });
      if(map1.getLayer("clusters")){
        map1.removeLayer("clusters");
        map1.removeLayer("cluster-count");
        map1.removeLayer("unclustered-point");
        map1.removeSource('marker-cluster');
      }
  }

  const handleNearPlaces = (id) => {
    var alreadyActive = false;
    nearPlaces = nearPlaces.map((obj1)=> {
      let img = document.getElementById('img-'+ obj1.id);
      if(obj1.id === id){
        if( obj1.active === true ){
          alreadyActive = true;
          img.src = "../images/"+obj1.title+".png";
          return { ...obj1, active: false}
        }
        img.src = "../images/"+obj1.title+"-active.png";
        return { ...obj1, active: true}
      }
      img.src = "../images/"+obj1.title+".png";
      return { ...obj1, active: false}
    });
    if(alreadyActive === true){
      removeLayerSourceAndCustomMarkers();
      setPlaceTypeObj({});
    }else{
      getPlacesByTypeId(id);
    }
  }

  const placeTypeModal = () => {

    if(Object.keys(placeTypeObj).length === 0) {
        return;
    }
    return (
      <>
      <div className="pop-up-marker-details">
        <div className="float-right" style={{cursor:'pointer'}}><FontAwesomeIcon icon={faTimes} size="1x" onClick={()=> {
            setPlaceTypeObj({});
        }} /></div>
        <div className="text-center">
          <img src={`../images/${placeTypeObj.thumbnail}`} alt={`${placeTypeObj.place_title}`} className="img img-responsive img-thumbnail pop-up-img" />
        </div>
        <h6><strong>{ placeTypeObj.place_title }</strong></h6>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <div className="d-inline w-50 distance-details distance-time-details"><FontAwesomeIcon icon={faCar} size="1x" /> {placeTypeObj.distance} km</div>
          <div className="d-inline w-50 time-details distance-time-details"><FontAwesomeIcon icon={faClock} size="1x" /> {placeTypeObj.time} mins</div>
        </div>
      </div>
      </>
    );
  } 

  
  const plotModal = () => {
    if(Object.keys(selectedPlot).length === 0) {
      return;
    }
  return <Modal
        show={modalDisplay}
        onHide={() => setModalDisplay(false)}
        // dialogClassName="modal-90w" // custom class is detains in app.css file
        size="lg"
        aria-labelledby="View selected plot"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-custom-modal-styling-title">
            Custom Modal Styling
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="p-0">
          {/* <pre>
            { JSON.stringify(selectedPlot,null,'\t') }
          </pre> */}
          <Container>
            <>
            {
              ( selectedPlot.generalEnquiry !== true ) ? <>
            <Row className="text-center">
              <Col xs={6} md={4} className="border-rb">
                <span><strong>{ selectedPlot.address.unit_number }</strong></span> <strong>{( selectedPlot.system_listing_state === "sold") ? <span className="text-danger d-block">Sold</span> : <span className="text-success d-block">Available</span> }</strong>
              </Col>
              <Col xs={6} md={4} className="border-rb">
                <FontAwesomeIcon icon={faArrowsAltH} size="1x"/> <strong>{ selectedPlot.attributes.land_frontage }</strong> Frontage
              </Col>
              <Col xs={6} md={4} className="border-b">
                <FontAwesomeIcon icon={faExpandArrowsAlt} size="1x"/> <strong> 
              
                  {/* buildarea */}
                  { (selectedPlot.attributes.landarea) && parseInt(selectedPlot.attributes.landarea) }{ selectedPlot.attributes.landarea_unit }
                  </strong> Size
              </Col>
            </Row>
            { ( selectedPlot.system_listing_state === "sold") && <div className="pt-3 mr-4 ml-4">
            <Alert variant='danger' className="text-center mb-0">
                <strong>Can't find your perfect lot? </strong>Contact our sales team for more opportunities.
                like.
            </Alert>
            </div>
            }
            </> : null }
            </>
            {/* display response */}
            { ( responseReceived.message !== "") && <div className="pt-2 pt-3 mr-4 ml-4">
            <Alert variant={ responseReceived.class } className="text-center mb-0">
                { responseReceived.message }
            </Alert>
            </div>
            }
            <div className="pt-2 pl-4 pr-4 pb-4">
            <h2 className="pb-3">Enquire Now</h2>
            <Form onSubmit={handleSubmit} >
              <Form.Group controlId="firstName">
                <Form.Control type="text" placeholder="First Name *" name="firstName" value={values.firstName || '' } onChange={onChange}/>
                { errors.firstName && <span className="text-danger">{ errors.firstName }</span> }
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Control type="text" placeholder="Last Name *" name="lastName" value={values.lastName || '' } onChange={onChange}/>
                { errors.lastName && <span className="text-danger">{ errors.lastName }</span> }
              </Form.Group>

              <Form.Group controlId="mobile">
                <Form.Control type="text" placeholder="Mobile *" name="mobile" value={values.mobile || '' } onChange={onChange}/>
                { errors.mobile && <span className="text-danger">{ errors.mobile }</span> }
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Control type="text" placeholder="Email *" name="email" value={values.email || '' } onChange={onChange}/>
                { errors.email && <span className="text-danger">{ errors.email }</span> }
              </Form.Group>

              <Form.Group controlId="postCode">
                <Form.Control type="text" placeholder="Post Code *" name="postCode" value={values.postCode || '' } onChange={onChange}/>
                { errors.postCode && <span className="text-danger">{ errors.postCode }</span> }
              </Form.Group>

              <Form.Group controlId="note">
                <Form.Control as="textarea" rows={3} placeholder="notes " name="note" value={values.note || '' } onChange={onChange}/>
                { errors.note && <span className="text-danger">{ errors.note }</span> }
              </Form.Group>

              <Form.Group controlId="reachId">
                <Form.Control as="select" onChange={changeSelect} name="hear_about_us_id">
                  { optionsList.map((obj, index)=> {
                    return <option key={index} value={index}>{obj}</option>
                  })}
                </Form.Control>
              </Form.Group>

              <Button variant="success" type="submit" className="pl-5 pr-5" disabled={ ( selectedPlot.system_listing_state === "sold") ? true : false }>
                Enquire <FontAwesomeIcon icon={faEnvelope} size="sm"/>
              </Button>
            </Form>
            </div>
          </Container>

        </Modal.Body>
      </Modal>
  }

  const airPortModal = () => {
    if(Object.keys(selectedAirPort).length === 0) {
      return;
    }
  return (<Modal
        show={airPortModalDisplay}
        onHide={() => {setSelectedAirPort({});setAirPortModalDisplay(false);}}
        // dialogClassName="modal-90w" // custom class is detains in app.css file
        size="lg"
        aria-labelledby="Viev air port details"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="container">
            <div className="row">
              <div className="w-80 mx-auto p-5">
              <div>
                { selectedAirPort.name }
              </div>
              <div className="mt-2 text-center">
                <a className="link-info" href={`${selectedAirPort.website_url}`} target="_blank">Visi Website</a>
              </div>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>)
  }

  const amenitiesModal = () => {
    if(Object.keys(selectedAmenity).length === 0) {
      return;
    }
  return (<Modal
        show={amenitiesModalDisplay}
        onHide={() => {setSelectedAmenity({});setAmenitiesModalDisplay(false);}}
        // dialogClassName="modal-90w" // custom class is detains in app.css file
        size="lg"
        aria-labelledby="Viev air port details"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="container">
            <div className="row">
              <div className="w-80 mx-auto p-5">
              <h4>
                <strong> { selectedAmenity.label } </strong>
              </h4>
              <p className="mt-2">
                {selectedAmenity.details}
              </p>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>)
  }

  useEffect(() => {
    getPlacesList();
    getInfoWindow();
    getAmenities();
    getReleasesList();
    var hours = 1; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        localStorage.setItem('setupTime', now);
        loginApi();
    } else {
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
            localStorage.setItem('setupTime', now);
            loginApi();
        }else {
          getSoldPlotsData();    
          getCurrentPlotsData();      
        }
    }
    SetRenderView( renderView + 1 );
  }, []);

  // create a function to make a directions request
  const getRoute = (end) => {
    // make a directions request using cycling profile
    // an arbitrary start will always be the same
    // only the end or destination will change
    var start =  [153.37509834611, -27.795487293718];
    var url = 'https://api.mapbox.com/directions/v5/mapbox/driving-traffic/' + start[0] + ',' + start[1] + ';' + end[0] + ',' + end[1] + '?steps=true&geometries=geojson&access_token=' + mapboxgl.accessToken;
  
    // make an XHR request https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
    var req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.onload = function() {
      var json = JSON.parse(req.response);
      var data = json.routes[0];
      var distanceInMeter = data.distance;
      var distanceInKM = Math.round(distanceInMeter / 1000);
      var durationInSec = data.duration;
      var durationInMinutes = Math.round(durationInSec / 60);
      var route = data.geometry.coordinates;
      var MidOfRoute = Math.floor(route.length / 2); // index 2
      var geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route
        }
      };
      // if(durationInSec == 0)
      // return false;
      
      // if the route already exists on the map, reset it using setData
      if (map1.getSource('route')) {
        map1.getSource('route').setData(geojson);
        
          var popupOld = document.getElementById("popup-custom");
          if(popupOld != null)
          popupOld.closest(".mapboxgl-popup").remove();
          var popup = new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat(route[MidOfRoute])
          .setHTML(`<span id="popup-custom">
                      <div>${distanceInKM} KM <br />(${durationInMinutes} mins)</div>
                    </span>`)
          .addTo(map1);

          var el = document.createElement('div');
          /* Assign a unique `id` to the marker. */
          el.id = "finish-marker";
          /* Assign the `marker` class to each marker for styling. */
          el.className = 'marker finish-marker';// custom-marker '+ obj.thumbnail;
          el.style.backgroundImage = `url(../images/finish-marker.png)`;
          /**
           * Create a marker using the div element
           * defined above and add it to the map.
          **/
           var Marker = new mapboxgl.Marker(el)
           .setLngLat(route[route.length - 1])
           .addTo(map1);

      } else { // otherwise, make a new request
        map1.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: geojson
              }
            }
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75
          }
        });
      }
      // add turn instructions here at the end
    };
    req.send();
  }

  const handleChange = (e) => {
    const { value } = e.target;
    if(value.length <= 0){
      if(map1.getLayer("route")){
        map1.removeLayer("route");
        map1.removeSource('route');
      }
      var finishMarker = document.getElementById("finish-marker");
      if(finishMarker != null)
      finishMarker.remove()
      var popupOld = document.getElementById("popup-custom");
      if(popupOld != null)
      popupOld.closest(".mapboxgl-popup").remove();
    }
  }
  
  const removeAllSourcesAndLayers = () => {

    if(map1.getSource("whole-source-area")){
      map1.removeLayer("area-layer");
      map1.removeLayer("whole-layer-hover");
      map1.removeLayer("whole-layer-border-hover");
      map1.removeSource('whole-source-area');
    }

    // releases
    if(map1.getSource("releases-source-area")){
      map1.removeLayer("area-layer");
      // map1.removeLayer("release-area-layer-outline");
      map1.removeLayer("release-layer-hover");
      map1.removeLayer("release-layer-border-hover");
      map1.removeSource('releases-source-area');
    }

    // individual 
    if(map1.getSource("individual-source-area")){
      map1.removeLayer("area-layer");
      // map1.removeLayer("area-layer-outline");
      map1.removeLayer("area-layer-hover-lot-number");
      map1.removeLayer("area-layer-border-hover-lot-number");
      map1.removeSource('individual-source-area');
    }
  }

  const addRasterImageSourceAndLayer = () => {
    // raster source
    map1.addSource('circle-shape-file-1', {
      'type': 'vector',
      'url': 'mapbox://atomicgroup.agxks07x'
    });
  
    // map1.addSource('myImageSource', {
    //   type: 'image',
    //   url: `../raster-image/image.jpg`,
    //   coordinates: [
    //     [153.361527, -27.784265],
    //     [153.382387, -27.787109],
    //     [153.379619, -27.80318],
    //     [153.358757, -27.800323],
    //   ],
    // });

    // map1.addLayer({
    //   id: 'overlay',
    //   source: 'myImageSource',
    //   type: 'raster',
    //   // paint: {
    //   //   'raster-opacity': 0.85,
    //   // },
    // });

  map1.addSource('circle-shape-file', {
    'type': 'vector',
    'url': 'mapbox://atomicgroup.68ks4by9'
  });

  map1.addLayer({
    'id': 'circle-shape-file-layer',
    'type': 'circle',
    'source': 'circle-shape-file',
    'source-layer': 'Circle_Mbox-37s6g2',
    'paint': {
      'circle-radius': 0.005,
      'circle-color': '#000',
      'circle-stroke-width': 0.005,
    }
  });
  
};


  const lotClicked = (e) => {
    var shapeFileLot = e.features[0].properties.Lot_Num;
    console.log(e.features[0].properties);
    filteredData.map((obj)=> {
      var rexLotNumber = obj.address.unit_number;
      if(rexLotNumber === null)
      return;
      rexLotNumber = rexLotNumber.replace(/[^0-9]/g,'');
      if(shapeFileLot === rexLotNumber){
        setModalDisplay(true);
        setSelectedPlot(obj);
      }
    });
  }

  const wholeShapeFileLayerAndSource = mapInstance => {
    
    mapInstance.addSource('whole-source-area', {
      'type': 'vector',
      'url': 'mapbox://atomicgroup.2yr5aez4'
    });
    //set up data layers
    mapInstance.addLayer({
        'id': 'area-layer',
        'type': 'fill',
        'source': 'whole-source-area',
        'source-layer': 'Whole_Area-bpwm0a',
        'paint': {
            'fill-outline-color': '#0066ff',
            'fill-color': '#0066ff',
            'fill-opacity': 0.5,
        },
        'paint.tilted': {}
    }, 'water');

    mapInstance.addLayer({
      "id": "whole-layer-hover",
      "type": "fill",
      'source': 'whole-source-area',
      'source-layer': 'Whole_Area-bpwm0a',
      "layout": {},
      'paint': {
          'fill-outline-color': '#ff0000',
          'fill-color': 'rgba(255, 255, 255, 0.5)',
          'fill-opacity': 0.75,
      },
      "filter": ["==", "Id", ""]
    });

    mapInstance.addLayer({
      'id': 'whole-layer-border-hover',
      'type': 'line',
      'source': 'whole-source-area',
      'source-layer': 'Whole_Area-bpwm0a',
      'paint': {
          'line-color': '#ffffff',
          'line-width': 4
      },
      "filter": ["==", "Id", ""]
    });
  }


  const releasesShapeFileLayerAndSource = mapInstance => {

    mapInstance.addSource('releases-source-area', {
      'type': 'vector',
      'url': 'mapbox://atomicgroup.053yovig'
    });
    //set up data layers
    mapInstance.addLayer({
        'id': 'area-layer',
        'type': 'fill',
        'source': 'releases-source-area',
        'source-layer': 'Developments-dptxxw',
        'paint': {
            'fill-outline-color': '#0066ff',
            'fill-color': '#0066ff',
            'fill-opacity': 0.5,
        },
        'paint.tilted': {}
    }, 'water');

    // mapInstance.addLayer({
    //     'id': 'release-area-layer-outline',
    //     'type': 'line',
    //     'source': 'releases-source-area',
    //     'source-layer': 'Developments-dptxxw',
    //     'paint': {
    //         'line-color': '#0066ff',
    //         'line-width': 1,
    //     }
    // });

    mapInstance.addLayer({
      "id": "release-layer-hover",
      "type": "fill",
      'source': 'releases-source-area',
      'source-layer': 'Developments-dptxxw',
      "layout": {},
      'paint': {
          'fill-outline-color': '#ff0000',
          'fill-color': '#ffffff',
          'fill-opacity': 0.5,
      },
      "filter": ["==", "Name", ""]
    });

    mapInstance.addLayer({
      'id': 'release-layer-border-hover',
      'type': 'line',
      'source': 'releases-source-area',
      'source-layer': 'Developments-dptxxw',
      'paint': {
          'line-color': '#ffffff',
          'line-width': 8
      },
      "filter": ["==", "Name", ""]
    });

  }

  const individualShapeFileLayerAndSource = mapInstance => {

    mapInstance.addSource('individual-source-area', {
      'type': 'vector',
      'url': 'mapbox://atomicgroup.b3f5i017'
    });
    //set up data layers
    mapInstance.addLayer({
        'id': 'area-layer',
        'type': 'fill',
        'source': 'individual-source-area',
        'source-layer': 'Lots-cameroon-Grove-6fnlkp',
        'paint': {
            'fill-outline-color': '#0066ff',
            'fill-color': '#0066ff',
            'fill-opacity': 0.5,
        },
        'paint.tilted': {}
    }, 'water');

    // mapInstance.addLayer({
    //     'id': 'area-layer-outline',
    //     'type': 'line',
    //     'source': 'individual-source-area',
    //     'source-layer': 'Lots-cameroon-Grove-6fnlkp',
    //     'paint': {
    //         'line-color': '#0066ff',
    //         'line-width': 1,
    //     }
    // });


    mapInstance.addLayer({
      "id": "area-layer-hover-lot-number",
      "type": "fill",
      'source': 'individual-source-area',
      'source-layer': 'Lots-cameroon-Grove-6fnlkp',
      "layout": {},
      'paint': {
          'fill-outline-color': '#ff0000',
          'fill-color': '#ffffff',
          'fill-opacity': 0.4,
      },
      "filter": ["==", "Lot_Num", ""]
    });

    mapInstance.addLayer({
      'id': 'area-layer-border-hover-lot-number',
      'type': 'line',
      'source': 'individual-source-area',
      'source-layer': 'Lots-cameroon-Grove-6fnlkp',
      'paint': {
          'line-color': '#ffffff',
          'line-width': 4
      },
      "filter": ["==", "Lot_Num", ""]
    });

    mapInstance.off('click', 'area-layer', lotClicked);
    mapInstance.on('click', 'area-layer', lotClicked);
  }

  const drawCircle = () => {

    let _center = turf.point([153.37509834611, -27.795487293718]);
    let radius_five = 5;
    let _options = {
    steps: 80,
    units: 'kilometers', // or "mile"
    };

    // first circle start 
    let firstCircle = turf.circle(_center, radius_five, _options);

    map1.addSource("circleData", {
      type: "geojson",
      data: firstCircle,
    });

    map1.addLayer({
      id: "circle-fill",
      type: "line",
      source: "circleData",
      paint: {
        'line-color': '#808080',
        'line-width': 4,
      },
      
    });

    var firstCircleArray = firstCircle.geometry.coordinates[0];
    var firstCircleArrayMidElement = Math.floor(firstCircleArray.length / 2);

    var el1 = document.createElement('div');
    el1.className = 'marker custom-text-marker';
    el1.innerText = "5 km";
    var marker1 = new mapboxgl.Marker(el1)
      .setLngLat(firstCircleArray[0])
      .addTo(map1);

    var el2 = document.createElement('div');
    el2.className = 'marker custom-text-marker';
    el2.innerText = "5 km";
    var marker2 = new mapboxgl.Marker(el2)
      .setLngLat(firstCircleArray[firstCircleArrayMidElement])
      .addTo(map1);

    // first circle end

    let radius_ten = 10;
    let circle_with_radius_ten = turf.circle(_center, radius_ten, _options);

    map1.addSource("circleDataOfRadiusTen", {
      type: "geojson",
      data: circle_with_radius_ten,
    });

    map1.addLayer({
      id: "circle-fill-with-radius-of-ten",
      type: "line",
      source: "circleDataOfRadiusTen",
      paint: {
        'line-color': '#808080',
        'line-width': 4,
      },
    });

    var secondCircleArray = circle_with_radius_ten.geometry.coordinates[0];
    var secondCircleArrayMidElement = Math.floor(secondCircleArray.length / 2);

    var el3 = document.createElement('div');
    el3.className = 'marker custom-text-marker';
    el3.innerText = "10 km";
    var marker3 = new mapboxgl.Marker(el3)
      .setLngLat(secondCircleArray[0])
      .addTo(map1);

    var el4 = document.createElement('div');
    el4.className = 'marker custom-text-marker';
    el4.innerText = "10 km";
    var marker4 = new mapboxgl.Marker(el4)
      .setLngLat(secondCircleArray[secondCircleArrayMidElement])
      .addTo(map1);

      
  }

  useEffect(() => {
    // if(propertyData.length === 0)
    // return true;
    if(mapContainerRef.current === undefined)
    return true;


      var mapStyle = {
        'version': 8,
        // 'name': 'Dark',
        'sources': {
            'mapbox': {
                'type': 'vector',
                'url': 'mapbox://styles/atomicgroup/ckmx06sfg0d5217qpp3h5cpzg'
            },
            'overlay': {
                'type': 'image',
                'url': `../raster-image/image.jpg`,
                'coordinates': [
                    [153.361527,-27.784265],
                    [153.382387,-27.787109],
                    [153.379619,-27.803180],
                    [153.358757,-27.800323]
                ]
            }
        },
        'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        'layers': [
            {
                'id': 'background',
                'type': 'background',
                'paint': { 'background-color': '#111' }
            },
            {
                'id': 'water',
                'source': 'mapbox',
                'source-layer': 'water',
                'type': 'fill',
                'paint': { 'fill-color': '#2c2c2c' }
            },
            {
                'id': 'boundaries',
                'source': 'mapbox',
                'source-layer': 'admin',
                'type': 'line',
                'paint': {
                    'line-color': '#797979',
                    'line-dasharray': [2, 2, 6, 2]
                },
                'filter': ['all', ['==', 'maritime', 0]]
            },
            {
                'id': 'overlay',
                'source': 'overlay',
                'type': 'raster',
                'paint': { 'raster-opacity': 0.85 }
            },
            {
                'id': 'cities',
                'source': 'mapbox',
                'source-layer': 'place_label',
                'type': 'symbol',
                'layout': {
                    'text-field': '{name_en}',
                    'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        4,
                        9,
                        6,
                        12
                    ]
                },
                'paint': {
                    'text-color': '#969696',
                    'text-halo-width': 2,
                    'text-halo-color': 'rgba(0, 0, 0, 0.85)'
                }
            },
            {
                'id': 'states',
                'source': 'mapbox',
                'source-layer': 'place_label',
                'type': 'symbol',
                'layout': {
                    'text-transform': 'uppercase',
                    'text-field': '{name_en}',
                    'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
                    'text-letter-spacing': 0.15,
                    'text-max-width': 7,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        4,
                        10,
                        6,
                        14
                    ]
                },
                'filter': ['==', ['get', 'class'], 'state'],
                'paint': {
                    'text-color': '#969696',
                    'text-halo-width': 2,
                    'text-halo-color': 'rgba(0, 0, 0, 0.85)'
                }
            }
        ]
    };
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: 'mapbox://styles/mapbox/streets-v11',
      style: 'mapbox://styles/atomicgroup/ckmx06sfg0d5217qpp3h5cpzg',
      // style:mapStyle,
      // center:  [153.37509834611, -27.795487293718],
      center: [151.5993548041857, -32.91383496390584],
      zoom: zoom,
      maxZoom: 19
    });

    map1 = map;    
    // goecoding with directions'api start
    var geocoder1 = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: mapboxgl.accessToken, // Set the access token
      mapboxgl: mapboxgl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
      countries: 'au',
      // bbox: [139.965, -38.03, 155.258, -27.839],
      bbox: [152.013423, -28.348348,153.529080, -27.660127],
      placeholder: "e.g. Gold Coast, Queensland, Australia"
      });
      // Add the geocoder to the map
      document.getElementById('geocoder1').appendChild(geocoder1.onAdd(map));

      var geocoder1Element = document.querySelector('#geocoder1');
      var geocoder1Input = geocoder1Element.querySelector('.mapboxgl-ctrl-geocoder--input');
      geocoder1Input.addEventListener('keyup', handleChange);
    // geocoding with dicrections'api end

    map.on('load', function () {

      drawCircle();
      //  Add a marker at the result's coordinates
      geocoder1.on('result', function (e) {

        // make an initial directions request that
        // starts and ends at the same location
        var start = [153.37509834611, -27.795487293718];
        getRoute(start);
        getRoute(e.result.geometry.coordinates);
        map.setZoom(10);
      });

      if(map.getZoom() < zoomLevel[0]){
        infoWindowList.map((obj) =>{
          if(obj.category === "Property name"){
            var popup = new mapboxgl.Popup({ closeOnClick: false })
                        .setLngLat([obj.lng, obj.lat])
                        .setHTML(`<span>${obj.label}</span>`)
                        .addTo(map);
          }
        });
      }


      removeAllSourcesAndLayers();
      addRasterImageSourceAndLayer();
      wholeShapeFileLayerAndSource(map);
      // releasesShapeFileLayerAndSource(map);
      // individualShapeFileLayerAndSource(map);

      map.on('mousemove', function (e) {
        var features = map.queryRenderedFeatures(e.point, {
            layers: ["area-layer"]
        });
        var currentZoom = map.getZoom();
        // Single out the first found feature.
        var ft = features[0];
        console.log(ft);
        var showTooltip = ft && ft.properties;
        //  Add features that share the same PARCEL_TYP to the hover layer.
        if (showTooltip) {
          if(currentZoom < zoomLevel[0]){
            map.setFilter('whole-layer-hover', [
                'in',
                'Id',
                ft.properties.Id
            ]);

            map.setFilter('whole-layer-border-hover', [
                'in',
                'Id',
                ft.properties.Id
            ]);
          }
          if(currentZoom >= zoomLevel[1] && currentZoom < zoomLevel[2] ){
            map.setFilter('release-layer-hover', [
                'in',
                'Name',
                ft.properties.Name
            ]);

            map.setFilter('release-layer-border-hover', [
                'in',
                'Name',
                ft.properties.Name
            ]);
        }
        if(currentZoom >= zoomLevel[2]){
          map.setFilter('area-layer-hover-lot-number', [
              'in',
              'Lot_Num',
              ft.properties.Lot_Num
          ]);

          map.setFilter('area-layer-border-hover-lot-number', [
              'in',
              'Lot_Num',
              ft.properties.Lot_Num
          ]);
        }
      }else{
        if(map1.getSource("whole-source-area")){
          map.setFilter('whole-layer-hover', ['in', 'Id', '']);
          map.setFilter('whole-layer-border-hover', ['in', 'Id', '']);
        }
        if(map1.getSource("releases-source-area")){
          map.setFilter('release-layer-hover', ['in', 'Name', '']);
          map.setFilter('release-layer-border-hover', ['in', 'Name', '']);
        }
        if(map1.getSource("individual-source-area")){
          map.setFilter('area-layer-hover-lot-number', ['in', 'Lot_Num', '']);
          map.setFilter('area-layer-border-hover-lot-number', ['in', 'Lot_Num', '']);
        }
      }
    });
    map.on('mouseout', function () {
      var currentZoom = map.getZoom();
      //map.getSource('area-highlight').setData(emptyAreaGeojson);
      if(currentZoom < zoomLevel[0]){
        map.setFilter('whole-layer-hover', ['in', 'Id', '']);
        map.setFilter('whole-layer-border-hover', ['in', 'Id', '']);
      }
      if(currentZoom >= zoomLevel[1] && currentZoom < zoomLevel[2] ){
        map.setFilter('release-layer-hover', ['in', 'Name', '']);
        map.setFilter('release-layer-border-hover', ['in', 'Name', '']);
      }
      if(currentZoom >= zoomLevel[2]){
        map.setFilter('area-layer-hover-lot-number', ['in', 'Lot_Num', '']);
        map.setFilter('area-layer-border-hover-lot-number', ['in', 'Lot_Num', '']);
      }
    });
    map.on('zoom', function(){
      let currentZoom = map.getZoom();
      document.querySelectorAll('.amenities-marker').forEach(function(a){
        a.remove()
      });
      if(currentZoom >= zoomLevel[0]) {
        // creating marker
        amenitiesList.map((obj)=> {
          var el = document.createElement('div');
          /* Assign a unique `id` to the marker. */
          el.id = "marker-amenities" + obj.id;
          /* Assign the `marker` class to each marker for styling. */
          el.className = 'marker amenities-marker';// custom-marker '+ obj.thumbnail;
          el.style.backgroundImage = `url(../amenities/${obj.thumbnail})`;
          /**
           * Create a marker using the div element
           * defined above and add it to the map.
          **/
          var Marker = new mapboxgl.Marker(el, { offset: [0, -23] })
            .setLngLat([Number(obj.lng), Number(obj.lat)])
            .addTo(map1);
          // use GetElement to get HTML Element from marker and add event
          Marker.getElement().addEventListener('click', () => {
            setSelectedAmenity(obj);  
            setAmenitiesModalDisplay(true);
          });
        });
      }


      if( currentZoom >= zoomLevel[2]){
        let vsc = document.getElementById('availible-sold-container');
        vsc.style.display = "block";
        
        let markers = document.getElementsByClassName("red-green-marker");
        for (let i = 0; i < markers.length; i++) {
            markers[i].style.visibility = "visible";
        }   
      }
      if(currentZoom < zoomLevel[2] ){
        let vsc = document.getElementById('availible-sold-container');
        vsc.style.display = "none";

        let markers = document.getElementsByClassName("red-green-marker");
        for (let i = 0; i < markers.length; i++) {
            markers[i].style.visibility = "hidden";
        }   
      }

      // display marker for airport
      if( currentZoom < 9) {
        airPortList.map((obj)=> {
          var el = document.createElement('div');
          el.id = "marker-airports" + obj.id;
          el.className = 'marker air-port-marker';
          el.style.backgroundImage = `url(../images/airport.png)`;
          var Marker = new mapboxgl.Marker(el, { offset: [0, -23] })
            .setLngLat([Number(obj.lng), Number(obj.lat)])
            .addTo(map1);
          Marker.getElement().addEventListener('click', () => {
            setSelectedAirPort(obj);
            setAirPortModalDisplay(true);
          });
        });
      }

      document.querySelectorAll('.mapboxgl-popup').forEach(function(a){
        a.remove()
      });

      if(currentZoom < zoomLevel[0]){
        infoWindowList.map((obj) =>{
          if(obj.category === "Property name"){
            var popup = new mapboxgl.Popup({ closeOnClick: false })
                        .setLngLat([obj.lng, obj.lat])
                        .setHTML(`<span>${obj.label}</span>`)
                        .addTo(map);
          }
        });
      }

      if(currentZoom >= zoomLevel[0] && currentZoom < zoomLevel[1] ){
        infoWindowList.map((obj) =>{
          if(obj.category !== "Releases"){
            var popup = new mapboxgl.Popup({ closeOnClick: false })
                        .setLngLat([obj.lng, obj.lat])
                        .setHTML(`<span>${obj.label}</span>`)
                        .addTo(map);
          }
        });
      }
      if(currentZoom >= zoomLevel[1] && currentZoom < zoomLevel[2]){
          infoWindowList.map((obj) =>{
            if(obj.category === "Releases"){
              var popup = new mapboxgl.Popup({ closeOnClick: false })
                          .setLngLat([obj.lng, obj.lat])
                          .setHTML(`<span>${obj.label}</span>`)
                          .addTo(map);
            }
          });
      }
      
      if( currentZoom < zoomLevel[0]){
        if(!map1.getSource('whole-source-area')){
          removeAllSourcesAndLayers();
          wholeShapeFileLayerAndSource(map);
        }
      }
      if(currentZoom >= zoomLevel[1] && currentZoom < zoomLevel[2] ){
        removeAllSourcesAndLayers();
        releasesShapeFileLayerAndSource(map);
      }
      if(currentZoom >= zoomLevel[2]){
        removeAllSourcesAndLayers();
        individualShapeFileLayerAndSource(map);
        // setTimeout(() => {
        //   showRedAndGreenMarkers();
        // }, 250);
      }
      
    });
  });
    map.on('move', () => {
      // setLng(map.getCenter().lng.toFixed(4));
      // setLat(map.getCenter().lat.toFixed(4));
      // zoom = map.getZoom().toFixed(2);
      setZoom(map.getZoom());
    });
    return () => map.remove();
  }, [renderView]);

  const removeAllMarkers = () => {
    document.querySelectorAll('.red-green-marker').forEach(function(a){
      a.remove()
    });
  }

  const openLotSearchPanel = () => {
    var mapContainer = document.getElementById("map-container");
    var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];

    // add class to map-container 
    mapContainer.classList.add("map-container-width");
    mapCanvas.style.width = "100%";

    // compass hiding
    var compass = document.getElementById("compass-container");
    compass.style.display = "none";

    var clypsoBayBtn = document.getElementById("clypso-bay-btn");
    clypsoBayBtn.style.display = "none";
  
    // zoom controls
    var zoomControls = document.getElementById('zoom-controls');
    zoomControls.classList.add("zoom-control-wrapper-left");
    zoomControls.classList.remove("zoom-control-wrapper-right");

    // showing lot search panel 
    var lotSearchPanel = document.getElementById('lot-search-panel');
    lotSearchPanel.classList.add("lot-search-panel-show");
    lotSearchPanel.style.display = "block";
    map1.resize();
}
const closeLotSearchPanel = () => {

  // showing lot search panel 
  var lotSearchPanel = document.getElementById('lot-search-panel');
  lotSearchPanel.classList.remove("lot-search-panel-show");
  lotSearchPanel.classList.add("lot-search-panel-hide");
  
  // compass showing
  var compass = document.getElementById("compass-container");
  compass.style.display = "block";

  var clypsoBayBtn = document.getElementById("clypso-bay-btn");
  clypsoBayBtn.style.display = "block";

  // zoom controls
  var zoomControls = document.getElementById('zoom-controls');
  zoomControls.classList.remove("zoom-control-wrapper-left");
  zoomControls.classList.add("zoom-control-wrapper-right");

  lotSearchPanel.classList.remove("lot-search-panel-hide");
  var mapContainer = document.getElementById("map-container");
  var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
  // add class to map-container 
  mapContainer.classList.remove("map-container-width");
  mapCanvas.style.width = "100%";
  lotSearchPanel.style.display = "none";
  map1.resize();
}
function findMinMaxLandarea(arr) {
  let min = arr[0].attributes.landarea; let max = arr[0].attributes.landarea;
  min = Math.round(min); max = Math.round(max);
  for (let i = 1, len=arr.length; i < len; i++) {
    let v = arr[i].attributes.landarea;
    v = Math.round(v);
    min = (v < min) ? v : min;
    max = (v > max) ? v : max;
  }

  return [min, max];
}

function findMinMaxLand_frontage(arr) {
  // attributes.land_frontage attributes.landarea
  let min = arr[0].attributes.land_frontage; let max = arr[0].attributes.land_frontage;
  min = Math.round(min); max = Math.round(max);
  for (let i = 1, len=arr.length; i < len; i++) {
    let v = arr[i].attributes.land_frontage;
    v = Math.round(v);
    min = (v < min) ? v : min;
    max = (v > max) ? v : max;
  }

  return [min, max];
}

const showRedAndGreenMarkers = () => {
  removeAllMarkers();
  var rexLotNumber;
  var rexLotNumbersArray = [];
  filteredData.filter((obj)=> (obj.address.unit_number !== null)).map((obj) => {
    rexLotNumber = obj.address.unit_number;
    rexLotNumber = rexLotNumber.replace(/[^0-9]/g,'');
    rexLotNumbersArray.push({rexLotNumber, system_listing_state: obj.system_listing_state});
  });

  var relatedFeatures = map1.querySourceFeatures('circle-shape-file', {
    sourceLayer: 'Circle_Mbox-37s6g2'
  });

  relatedFeatures.map(( obj ) => {
    var coords = obj.geometry.coordinates;
    var props = obj.properties;
    var Lot_Num = obj.properties.Lot_Num;
    var id = props.cluster_id;

    var el = document.createElement('div');
    el.id = "marker-" + id; 
    el.className = 'red-green-marker';
    el.style.visibility = `hidden`;
    el.style.backgroundImage = `url(../images/no-info-17x17.png)`;

    for (let index = 0; index < rexLotNumbersArray.length; index++) {
      const element = rexLotNumbersArray[index];
      if(rexLotNumbersArray[index].rexLotNumber === Lot_Num){
        el.style.backgroundImage = `url(../images/available-17x17.png)`;
        if(rexLotNumbersArray[index].system_listing_state === "sold")
        el.style.backgroundImage = `url(../images/sold-17x17.png)`;
        break;
      }     
    }
    var Marker = new mapboxgl.Marker(el)
      .setLngLat(coords)
      .addTo(map1);

    // use GetElement to get HTML Element from marker and add event
    // Marker.getElement().addEventListener('click', () => {
    //   setModalDisplay(true);
    //   setSelectedPlot(obj);

    // });
  });
}

const createRedAndGreenMarkers = (markersList) => {
  removeAllMarkers();
  markersList.map((obj, index)=> {
    var el = document.createElement('div');
    /* Assign a unique `id` to the marker. */
    el.id = "marker-" + obj._id;
    /* Assign the `marker` class to each marker for styling. */
    
    el.setAttribute('lat', obj.address.latitude)
    el.setAttribute('lng', obj.address.longitude)

    // old class name is ==> mapboxgl-marker
    el.className = 'red-green-marker';
    el.style.backgroundImage = `url(../images/available-17x17.png)`;
    if(obj.system_listing_state === "sold")
    el.style.backgroundImage = `url(../images/sold-17x17.png)`;
    el.style.visibility = `hidden`;
    /**
     * Create a marker using the div element
     * defined above and add it to the map.
    **/
    var Marker = new mapboxgl.Marker(el, { offset: [0, -23] })
      .setLngLat([Number(obj.address.longitude), Number(obj.address.latitude)])
      .addTo(map1);
    // use GetElement to get HTML Element from marker and add event
    // Marker.getElement().addEventListener('click', () => {
    //   setModalDisplay(true);
    //   setSelectedPlot(obj);

    // });
  });
}
  
  useEffect(() => {
    
    if(map1 === undefined)
    return;

    filteredData = propertyData;
      if(lotSearch){
        filteredData = filteredData.filter( (obj) => {
          // if(obj.attributes.landarea)
                      let frontage = obj.attributes.land_frontage;
                      let landarea = obj.attributes.landarea;
                      //frontage
                      return (
                        lotFrontageArrRange[0] < frontage && lotFrontageArrRange[1] > frontage
                        && 
                        lotSizeArrRange[0] < landarea && lotSizeArrRange[1] > landarea
                        )
                    });
                    if(filteredData.length === 0 ) {
                      setLotSearchMsg("No land matching this criteria found.");
                    }else{
                      setLotSearchMsg("");
                    }
        setFilteredLots(filteredData);

      }
      showRedAndGreenMarkers();
      // createRedAndGreenMarkers(filteredData);
      // console.log(findMinMaxLandarea(propertyData));
      // console.log(findMinMaxLand_frontage(propertyData));
  }, [propertyData, lotSearch])

  return (
    <div>
      { plotModal() }
      { airPortModal() }
      { amenitiesModal() }
      { placeTypeModal() }
      { ( renderView === 0 ) ? <Loader /> : <div className="container">
        {/* top filter container */}
        <div className="filters-container-top">
        <div className="d-flex width-100 justify-content-between">
          <div className="p-2 left-fitlers-wrapper">
            {/* <div>Search Places near by: <div className="geocoder search-near-places" id="geocoder"></div></div>  */}
            {/* <input type="text" placeholder="gym, parks, sports areas etc." className="custom-input" /> */}
          </div>
          <div className="p-2 right-filter">
            <div >Directions from Cameron to: <div className="geocoder search-directions" id="geocoder1"></div></div>
          </div>
        </div>
      </div>
        {/* top filter container end */}
      <div className="places-types">
        {/* Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} */}
        {
          nearPlaces.map( (obj, index) => {
            return <span className="circle" key={index} onClick={ () => { handleNearPlaces(obj.id) }}>
                    <OverlayTrigger
                          key={index}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-${index}`}>
                              {`${ (obj.title).charAt(0).toUpperCase() + (obj.title).slice(1)}`}
                            </Tooltip>
                          }
                        >
                      <img src={`../images/${obj.title}.png`} alt={`${obj.title}`} className="img-size" id={`img-${obj.id}`}/>
                    </OverlayTrigger>
                  </span>
          })
        }
      </div>
      <div className="compass-container" id="compass-container">
        <img src={`../images/compass.png`} alt="compass" />
      </div>
      <div className="availible-sold-container" id="availible-sold-container">
        <img src={`../images/available-17x17.png`} alt="Available" /> Available <br />
        <img src={`../images/sold-17x17.png`} alt="Sold" /> Sold
      </div>
      <div className="map-container" ref={mapContainerRef} id="map-container" />
      {/* plot search panel */}
      <div 
      style={{ display: 'none'}}
       id="lot-search-panel">
        <div className="float-left close-button" onClick={()=> {
          closeLotSearchPanel();
          map1.flyTo({
            zoom: zoomLevel[0] -1,
            speed: 1, // make the flying slow
          });
          setFilteredLots([]);
          setLotSearch(false);
        }} ><FontAwesomeIcon icon={faTimes} size="1x"/></div>
        <br />
        <div className="range-slider-wrapper">
        <div style={wrapperStyle}>
          <div className="">
            <FontAwesomeIcon icon={faArrowsAltH} size="1x"/> <strong> LOT FRONTAGE m</strong>
            <Range min={8} max={20} defaultValue={[8, 20]}
                // marks={{ 5: 5, 10: 10, 15: 15, 20: 20, 25: 25 }}
                handle={handle}
                onAfterChange={(val)=> lotFrontageArrRange = val }
                tipFormatter={value => `${value}m`} step={0.5}
                />
          </div>
        <div className="range-slider">
          <FontAwesomeIcon icon={faExpandArrowsAlt} size="1x"/><strong> LOT SIZE m2 </strong>
          <Range min={290} max={460} defaultValue={[290, 460]}
              // marks={{ 5: 5, 10: 10, 15: 15, 20: 20, 25: 25 }}
              handle={handle}
              onAfterChange={(val)=> lotSizeArrRange = val }
              tipFormatter={value => `${value}m2`} step={10} />
        </div>
        <button className="mt-4 btn-blue" onClick={()=> {
          setLotSearch(false);
          setFilteredLots([]);
          map1.flyTo({
            zoom: zoomLevel[2],
            speed: 1, // make the flying slow
          });
          // setLotSearch(true);
          
          setTimeout(() => {            
            setLotSearch(true);
          }, 1000);
        }}>Search</button>
        </div>
        </div>
        <div>
          { (filteredLots.length > 0) ? <div className="table-responsive tableFixHead">          
  <table className="table table-bordered table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>State</th>
        <th>Size</th>
        <th>Frontage</th>
        <th>Location</th>
      </tr>
    </thead>
    <tbody>
      { 
      filteredLots.map( (obj, index) => {
        return <tr key={index}>
            <td>{index + 1}</td>
            <td>{(obj.system_listing_state === "sold") ? <span className="text-danger">Sold</span> : <span className="text-success">Availible</span>}</td>
            <td>{ Math.round(obj.attributes.landarea) }{ obj.attributes.landarea_unit }</td>
            <td>{ Math.round(obj.attributes.land_frontage) }m</td>
            <td><button className="btn-sm" onClick={()=> {
              map1.flyTo({
                center: [obj.address.longitude, obj.address.latitude],
                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
              });
        }}>View</button></td>
          </tr>
      })}
    </tbody>
  </table>
  </div> : <h5 className="primary-color pl-3 pr-3">{lotSearchMsg}</h5>}
        </div>
      </div>
      {/* plot search panel end */}
      {
        ( releasesList.length > 0 ) && <div className="clypso-bay-btn" id="clypso-bay-btn" onClick={ ()=> {
          map1.flyTo({
            center: [151.5993548041857, -32.91383496390584],
            essential: true,
            zoom: 14
          });
      }}>
    <span>Back to Cameron Grove</span>
  </div>
      }

      {/* zoom controls starts */}
      <div className="zoom-control-wrapper zoom-control-wrapper-right" id="zoom-controls">
        <div className="d-flex flex-column">
      <FontAwesomeIcon icon={faPlusSquare} size="1x" onClick={()=> {
        map1.flyTo({
          zoom: zoom + 1,
          speed: 1, // make the flying slow
        });
        setZoom( zoom + 1);
      }} />
      <FontAwesomeIcon  icon={faMinusSquare} size="1x" onClick={()=> {
        map1.flyTo({
          zoom: zoom - 1,
          speed: 1, // make the flying slow
        });
        setZoom( zoom - 1)
      }} />
      </div>
      </div>

      {/* phone number */}
      <div className="phone-number-wrapper">
        <FontAwesomeIcon icon={faPhone} size="1x" /><h5 className="d-inline ml-2">1800 123 4567</h5>
      </div>
      {/* end phone number */}

      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        // nextButton={<button className="mt-5 btn-blue" onClick={()=> console.log("next") }>Next</button>}
        // prevButton={<button className="mt-5 btn-blue" onClick={()=> console.log("prev") }>Prev</button>}
        showCloseButton={true}
        showNumber={false}
        showNavigation={false}
        showButtons={false} //to hide button i-e next and prevous
      />
      {/* bottom filters section */}
      <div className="filters-container-bottom">
        <div className="d-flex width-100 justify-content-between">
          <div className="p-2 left-fitlers-wrapper">
            <div className="d-flex justify-content-around">
            {/* <div className="pl-3 pt-1 lots-search" style={{cursor:'pointer'}} onClick={ ()=> openLotSearchPanel()}>
              <FontAwesomeIcon icon={faSearch} size="1x"/> Lots Search
            </div> */}

            <div className="pl-3" style={{cursor:'pointer'}}>
                  <label htmlFor="zoom_to">Zoom To: </label>
                  <select name="zoom_to" className="custom-select zoom-to" id="zoom_to" style={{ display: 'inline-block'}} onChange={changeZoomTo}>
                  <optgroup label="Releases">
                    { 
                      releasesList.filter((obj)=> obj.id !== "1" ).map((obj)=> <option value={obj.id} obj={obj} key={obj.id}>{obj.release_label }</option>)
                    }
                  </optgroup>
                  <optgroup label="Cameroon">
                    { 
                      releasesList.filter((obj)=> obj.id === "1" ).map((obj)=> <option value={obj.id} obj={obj} key={obj.id}>{obj.release_label }</option>)
                    }
                  </optgroup>
                  </select>
            </div>
            </div>
          </div>
          <div className="p-2 right-filter">
          <div className="d-flex justify-content-around">
            {/* <div className="pl-3">
              <FontAwesomeIcon icon={faFile} size="1x" onClick={()=> {
              setPlaceTypeObj({});
                }} />
                &nbsp;
                  <label htmlFor="sel1">Stage Brochures: </label>
                  <select name="from_year" className="custom-select stage-brochures" id="from_year" style={{ display: 'inline-block'}} onChange={ (e) => {
                    const { value } = e.target;
                    window.open(`../PDF/${value}.pdf`, "_blank")
                  }}>
                    <option value="01">Release 1</option>
                    <option value="02">Release 2</option>
                    <option value="03">Release 3</option>
                    <option value="04">Release 4</option>
                  </select>
            </div> */}
            <div className="pt-1 ml-1">
            <span style={{cursor:'pointer'}} onClick={()=> { setModalDisplay(true); setSelectedPlot({ generalEnquiry: true}); }} ><FontAwesomeIcon icon={faEnvelope} size="1x"/> Enquire</span>
            <span style={{cursor:'pointer'}} className="ml-5"><FontAwesomeIcon icon={faQuestionCircle} size="1x" style={{ backgroundColor: 'navy'}} onClick={()=> { setIsTourOpen(true); }} /></span>
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom container end */}
      </div>
      }
    </div>
  );
};

export default Cameroon;
