import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
// import Map from "./components/Map";
import Map2 from "./components/Map2";
import Cameroon from "./components/Cameroon";
import HarringtonWaters from './components/HarringtonWaters';

// https://www.positronx.io/react-mern-stack-crud-app-tutorial/

const App = () => {
  // return (<>
  //   {/* <GetPropertiesListing /> */}
  //   <Map />
  //   </>
  // );

  return (
  <Router>
  <Switch>
       <Route path="/Harrington-waters">
            <HarringtonWaters />
       </Route>
       <Route path='/Cameron-grove'>
            <Cameroon />
       </Route>
       <Route path="/Calypso-bay">
            <Map2 />
       </Route>
       <Route path="/">
            <Map2 />
       </Route>
  </Switch>
</Router>)
}

export default App;
